@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.6/dist/web/static/pretendard.css");

body,
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;

  font-family: "Pretendard";

  user-select: none; /* Disable text selection */
  -webkit-user-drag: none; /* Disable text dragging on WebKit browsers */
  -webkit-user-select: none; /* Disable text selection on WebKit browsers */
  -moz-user-select: none; /* Disable text selection on Mozilla browsers */
  -ms-user-select: none; /* Disable text selection on Microsoft browsers */
}

.background {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  min-width: 100vw;
  min-height: 100vh;

  background: #0b0933;
  background: linear-gradient(
    344deg,
    #0b0933 12%,
    #041b83 42%,
    #4302b5 68%,
    #9608f5 98%
  );
}
